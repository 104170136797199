import React from "react";
import { Spinner } from "react-bootstrap";
import { AuthConsumer } from "./AuthProvider";

export const LogInCallback: React.FC = () => (
  <AuthConsumer>
    {({ signinRedirectCallback }) => {
      signinRedirectCallback();
      return (
        <div className="d-flex align-items-center justify-content-center h-100">
          <Spinner animation="border" role="status" variant="primary" />
          &nbsp;Sie wurden angemeldet, verarbeite Anmelde-Daten ...
        </div>
      );
    }}
  </AuthConsumer>
);
